<template>
	<el-dialog :visible.sync="show" width="70%" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
	 @close="close">
		<div slot="title" class="header-title">
			<el-row :gutter="5">
				<el-col :span="24">
					<span class="title-name">{{ info.title }}</span>
				</el-col>
			</el-row>
		</div>
		<el-card>
			<el-row type="flex" justify="space-between" :gutter="50">
				<el-col>
					<span class="text-primary text-pad-right">公司</span>
					<el-select v-model="condition.companyId" class="searchInput" size="mini" filterable clearable @change="getList">
						<el-option v-for="(v,i) in company" :key="v.id" :value="v.id" :label="v.companyName" />
					</el-select>
				</el-col>
				<el-col>
					<el-input v-model="condition.search" class="input searchInput" size="mini" suffix-icon="el-icon-search"
					 placeholder="搜索" @input="searchInput" />
				</el-col>
			</el-row>
		</el-card>
		<el-card style="margin-top: 20px;">
			<el-row>
				<vxe-toolbar>
					<template v-slot:buttons>
						<!-- <vxe-button @click="getList">刷新</vxe-button> -->
						<el-row type="flex">
							<el-col :span="19">
								<vxe-button @click="getList">刷新</vxe-button>
							</el-col>
							<el-col :span="5">
								<vxe-button @click="batchBind">批量关联</vxe-button>
								<vxe-button @click="batchUnbind">批量取消关联</vxe-button>
							</el-col>
						</el-row>
					</template>
				</vxe-toolbar>
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
				 align="center" class="vxe-table-element" height="600" row-id="notice_id" :sort-config="{remote: true}"
				 :filter-config="{remote: true}" :checkbox-config="{reserve: true}" :data="table.list" :loading="loading"
				 @checkbox-all="handleSelectionChange" @checkbox-change="handleSelectionChange">
					<vxe-table-column type="checkbox" width="60" fixed="left" />
					<vxe-table-column type="seq" width="60" />
					<vxe-table-column field="realName" title="姓名" />
					<vxe-table-column field="companyName" title="公司名称" />
					<vxe-table-column field="departName" title="部门名称" />
					<vxe-table-column field="phone" title="电话号码" />
					<vxe-table-column field="idCard" title="身份证号码" />
					<vxe-table-column title="设置关联">
						<template v-slot="{ row, rowIndex }">
							<el-switch ref="switch" v-model="row.relationStatus" active-color="#13ce66" inactive-color="#ff4949"
							 :active-value="0" :inactive-value="1" @change="(v)=>{changeStatus(v,row)}" />
						</template>
					</vxe-table-column>
				</vxe-table>
				<el-pagination background :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]" :page-size="table.pageSize"
				 layout="total, ->, sizes, prev, pager, next, jumper" :total="table.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" />
			</el-row>
		</el-card>
	</el-dialog>
</template>

<script>
	import {
		rowStyle,
		headerStyle
	} from '@/utils/tableStyleJs'
	import {
		sysUserNoticeId,
		notice
	} from '@/api'

	export default {
		name: 'SetUser',
		props: {
			// 打开dialog的
			dialog: {
				type: Boolean, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: false // 这样可以指定默认的值
			},
			info: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			},
			row: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			}
		},
		data() {
			return {
				loading: false,
				show: this.dialog,
				condition: {
					search: '',
					companyId: ''
				},
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
					sortName: '',
					sortBy: ''
				},
				company: [],
				userIds: []
			}
		},
		created() {
			this.getCompany()
		},
		methods: {
			rowStyle,
			headerStyle,
			searchInput(v) {
				// this.getList()
				if (this.condition.companyId) {
					this.getList()
				}
			},
			getCompany() {
				this.$axiosReq(notice + this.row.id, null, null, 'get').then(res => {
					this.company = res.data.companys
					if (this.company && this.company.length > 0) {
						this.condition.companyId = this.company[0].id;
						this.getList()
					}
				})
			},
			getList() {
				this.loading = true
				this.userIds = [];
				this.$axiosReq(sysUserNoticeId + this.row.id, null, {
					commonField: this.condition.search,
					companyId: this.condition.companyId,
					currentPage: this.table.currentPage,
					pageSize: this.table.pageSize
				}, 'get').then(res => {
					this.table.list = res.data.content
					this.table.total = Number(res.data.totalElements)
				}).finally(e => {
					this.loading = false
				})
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize
				this.table.currentPage = 1
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage
				this.getList()
			},
			handleSelectionChange(val) {
				const data = val.records;
				this.userIds = [];
				for (let i = 0; i < data.length; i++) {
					this.userIds.push(data[i].id);
				}
			},
			batchUnbind() {
				if (!this.userIds || this.userIds.length < 1) {
					this.$message.error('请选择人员');
					return false;
				}
				this.$axiosReq('/oauth/server/escort/web/sysUser/relevance/' + this.row.id, {
					companyId: this.condition.companyId,
					relevance: 1,
					userIds: this.userIds
				}, null, 'post').then(res => {
					this.$message.success(res.msg);
					this.getList();
				}).finally(e => {
					this.loading = false
				})
			},
			batchBind() {
				if (!this.userIds || this.userIds.length < 1) {
					this.$message.error('请选择人员');
					return false;
				}
				this.$axiosReq('/oauth/server/escort/web/sysUser/relevance/' + this.row.id, {
					companyId: this.condition.companyId,
					relevance: 0,
					userIds: this.userIds
				}, null, 'post').then(res => {
					this.$message.success(res.msg);
					this.getList();
				}).finally(e => {
					this.loading = false
				})
			},
			changeStatus(v, row) {
				this.$axiosReq(notice + this.row.id, null, {
					userId: row.id
				}, 'post').then(res => {
					this.$message.success(res.msg)
					this.getList()
				}).finally(e => {
					this.loading = false
				})
			},
			// 关闭模态框
			close() {
				this.show = false
				this.$emit('refresh')
			}
		}
	}
</script>

<style scoped>

</style>
