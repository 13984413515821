<template>
	<div class="exportPerson flex-row-box flex-justify-center flex-cont-center" @click="closeBox">
		<div class="exportPersonBox">
			<div class="flex-row-box headerList">
				<div class="exportTitle" />
				<div class="flex-1 text-center">公告内容</div>
				<div class="text-center exportTitle" @click="closeOver"><i class="el-icon-error" /></div>
			</div>
			<div class="contentText">
				<div v-html="noticeText"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import { axiosReqCross } from "@/utils/request";
	export default {
		name: 'SetUser',
		props: {
			type: String,
			row: ''
		},
		data() {
			return {
				loading: false,
				show: true,
				noticeText: ''
			}
		},
		created() {
			this.noticeText = this.row;
		},
		methods: {
			closeBox(e) {
				if (e.target.className == 'exportPerson flex-row-box flex-justify-center flex-cont-center') {
					this.show = false
					this.$emit('refresh')
				}
			},
			closeOver() {
				this.show = false
				this.$emit('refresh')
			}
		}
	}
</script>

<style scoped>
	.exportPerson {
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		background-color: rgba(0, 0, 0, 0.3);
		z-index: 99;
	}

	.exportPersonBox {
		width: 50%;
		height: 90%;
		background-color: #FFFFFF;
	}

	.headerList {
		padding: 20px 0;
		border-bottom: 1px solid #c3c3c3;
		color: #5C8BFF;
		font-weight: bold;
		font-size: 20px;
		height: 10%;
		box-sizing: border-box;
	}

	.exportTitle {
		width: 50px;
	}

	.contentText {
		font-size: 18px;
		padding: 30px;
		height: 90%;
		box-sizing: border-box;
		overflow-y: scroll;
	}
</style>
