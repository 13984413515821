<template>
    <el-dialog
        :visible.sync="show"
        width="50%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        @close="close"
    >
        <div slot="title" class="header-title">
            <el-row :gutter="5">
                <el-col :span="24">
                    <span class="title-name">{{ info.title }}</span>
                </el-col>
            </el-row>
        </div>
        <el-row :gutter="0">
            <el-col :span="24">
                <el-form
                    ref="ruleForm"
                    label-width="110px"
                    class="allForm"
                    :disabled="info.addOr === 'view'"
                >
                    <el-form-item label="公告标题" :required="true">
                        <el-input
                            v-model="form.noticeTitle"
                            class="formInput"
                        />
                    </el-form-item>
                    <el-form-item label="公告有效期" :required="true">
                        <el-date-picker
                            v-model="form.failureTime"
                            type="date"
                            class="formInput"
                            clearable
                            value-format="yyyy-MM-dd"
                        />
                    </el-form-item>
                    <el-form-item
                        label="公告内容"
                        :required="true"
                        class="formInput"
                    >
                        <wang
                            :content-back="form.noticeContentHtml"
                            :disabled="info.addOr === 'view'"
                            @get-content="getContent"
                        />
                    </el-form-item>
                    <!-- <el-form-item label="生成内容">
                        <el-button type="primary" @click="save"
                            >生成内容文件</el-button
                        >
                    </el-form-item>
                    <el-form-item label="公告内容文件" :required="true">
                        <el-upload
                            ref="upload"
                            class="upload-demo"
                            drag
                            :headers="{ Authorization: $token }"
                            :action="$srcUrl + upFile"
                            auto-upload
                            list-type="file"
                            :file-list="fileList"
                            :limit="1"
                            :before-upload="change"
                            :on-success="fileSuccessContent"
                            :on-exceed="overFile"
                        >
                            <i
                                class="el-icon-receiving"
                                style="font-size: 80px"
                            />
                            <div class="el-upload__text">
                                将文件拖到此处，或<em>点击上传</em>，只支持传一个文件（文件后缀为.html）
                            </div>
                        </el-upload>
                    </el-form-item> -->
                    <el-form-item label="公告附件">
                        <el-upload
                            ref="upload"
                            class="upload-demo"
                            drag
                            :headers="{ Authorization: $token }"
                            :action="$srcUrl + upFile"
                            auto-upload
                            list-type="file"
                            :file-list="fileList2"
                            :limit="1"
                            :before-upload="change2"
                            :on-success="fileSuccessAttach"
                            :on-exceed="overFile"
                        >
                            <i
                                class="el-icon-receiving"
                                style="font-size: 80px"
                            />
                            <div class="el-upload__text">
                                将文件拖到此处，或<em>点击上传</em>，只支持传一个文件
                            </div>
                            <div>.doc ,.docx,.xls,.xlsx,.ppt,.pptx,.pdf</div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item
                        label="公司名称"
                        :required="true"
                        class="formInput"
                    >
                        <el-select
                            v-model="form.companyIds"
                            class="w-100"
                            filterable
                            multiple
                            clearable
                            @change="companyChange"
                        >
                            <el-option
                                v-for="v in company"
                                :key="v.id"
                                :value="v.id"
                                :label="v.companyName"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="部门名称" class="formInput">
                        <el-cascader
                            ref="dept"
                            v-model="form.departIds"
                            :options="depart"
                            class="w-100"
                            :props="{
                                expandTrigger: 'hover',
                                value: 'id',
                                label: 'departName',
                                children: 'sons',
                                leaf: 'id',
                                checkStrictly: true,
                                emitPath: false,
                                multiple: true,
                            }"
                            :show-all-levels="false"
                            filterable
                            clearable
                        />
                    </el-form-item>
                    <!-- <el-form-item label="是否所有人可见" :required="true" class="formInput">
            <el-select v-model="form.openOrSecret" class="w-100">
              <el-option :value="0" label="是" />
              <el-option :value="1" label="否" />
            </el-select>
          </el-form-item> -->
                    <template>
                        <el-form-item label="公告视频">
                            <el-upload
                                ref="upload"
                                class="upload-demo w-100"
                                drag
                                name="file"
                                :headers="{ Authorization: $token }"
                                :action="$srcUrl + upFile"
                                list-type="file"
                                :file-list="videoList"
                                auto-upload
                                :limit="1"
                                :before-upload="changeVideoFile"
                                :on-progress="videoProgress"
                                :on-success="videoSuccess"
                                :on-exceed="overFile"
                            >
                                <i
                                    class="el-icon-receiving"
                                    style="font-size: 80px"
                                />
                                <div class="el-upload__text">
                                    将文件拖到此处，或<em>点击上传</em>，只支持传一个文件
                                </div>
                            </el-upload>
                        </el-form-item>
                        <!--            <el-form-item label="视频名称">-->
                        <!--              <el-input v-model="form.videoName" class="formInput" readonly="" />-->
                        <!--            </el-form-item>-->
                        <!--            <el-form-item label="视频路径">-->
                        <!--              <el-input v-model="form.videoUrl" class="formInput" readonly="" />-->
                        <!--            </el-form-item>-->
                    </template>
                </el-form>
            </el-col>
        </el-row>
        <div
            v-if="info.addOr !== 'view'"
            slot="footer"
            class="dialog-footer"
            style="text-align: center"
        >
            <el-button type="primary" @click="commit">提交</el-button>
            <el-button type="info" @click="close">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import wang from "@/components/WangEditor";
import { company, notice, upFile, depart } from "@/api";
import { uploadAccept } from "@/utils/uploadAccept";
import { downFile } from "@/utils/file";
import { axiosReqCross } from "@/utils/request";
export default {
    name: "Operate",
    components: {
        wang,
    },
    props: {
        // 打开dialog的
        dialog: {
            type: Boolean, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: false, // 这样可以指定默认的值
        },
        info: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
        row: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
    },
    data() {
        return {
            show: this.dialog,
            form: {
                noticeTitle: "",
                noticeContent: "",
                noticeContentHtml: "",
                noticeFileName: "",
                // openOrSecret: 0,
                noticeType: "",
                noticeAttachedUrl: "",
                failureTime: "",
                companyIds: this.$admin ? [] : [this.$companyId],
                departIds: [],
                videoName: "",
                videoUrl: "",
            },
            companyIds: [],
            upFile,
            noticeContent: "",
            file: "",
            fileList: [],
            fileList2: [],
            videoList: [],
            videoLoading: "",
            company: [],
            depart: [],
        };
    },
    created() {
        this.getCompany();
        if (this.info.addOr == "add") {
            this.getDepart(this.$companyId);
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.upd();
        });
    },
    methods: {
        upd() {
            if (this.info.addOr !== "add") {
                this.$axiosReq(notice + this.row.id, null, null, "get").then(
                    (res) => {
                        this.form.noticeTitle = res.data.noticeTitle;
                        this.form.noticeContent = res.data.noticeContent;
                        this.form.noticeContentHtml = res.data.noticeContentHtml;
                        this.form.noticeFileName = res.data.noticeFileName;
                        this.form.openOrSecret = res.data.openOrSecret;
                        this.form.noticeType = res.data.noticeType;
                        this.form.noticeAttachedUrl =
                            res.data.noticeAttachedUrl;
                        this.form.failureTime = res.data.failureTime;
                        this.form.videoName = res.data.videoName;
                        this.form.videoUrl = res.data.videoUrl;
                        if (res.data.companys || res.data.companys.length > 0) {
                            for (const i of res.data.companys) {
                                if (this.$companyType == 0 && !this.$admin) {
                                    if (i.id != this.$companyId) {
                                        this.form.companyIds.push(i.id);
                                    }
                                } else {
                                    // this.form.companyIds.push(i.id);
                                    if (i.id != this.$companyId) {
                                        this.form.companyIds.push(i.id);
                                    }
                                }
                            }
                        }
                        this.form.departIds = res.data.departIds;
                        // this.fileList.push({
                        //     url: this.$fileUrl + this.form.noticeContentUrl,
                        //     name: this.form.noticeContentUrl ? "文件" : "",
                        // });
                        if (this.form.noticeAttachedUrl) {
                            this.fileList2.push({
                                url:
                                    this.$fileUrl + this.form.noticeAttachedUrl,
                                name: this.form.noticeFileName,
                            });
                        }
                        if (this.form.videoUrl) {
                            this.videoList.push({
                                url: this.$fileUrl + this.form.videoUrl,
                                name: this.form.videoName,
                            });
                        }
                        // this.getContentUpd();
                        if (this.form.companyIds.length == 1) {
                            this.getDepart(this.form.companyIds[0]);
                        } else if (this.form.companyIds.length < 1) {
                            this.getDepart(this.$companyId);
                        }
                    }
                );
            }
        },
        changeVideoFile(file) {
            const fileSize = file.size / 1024 / 1024 < 500;
            if (!fileSize) {
                this.$message.error("文件大小不能超过500MB！！！");
                return false;
            }
            let flag = false;
            flag = uploadAccept(file, "视频");
            return flag;
        },
        videoSuccess(r, f, l) {
            this.form.videoName = r.data.fileName;
            this.form.videoUrl = r.data.filePath;
            this.videoLoading.close();
        },
        overFile() {
            this.$message.error("只能传一个！");
        },
        change(file, fileList) {
            const fileSize = file.size / 1024 / 1024 < 500;
            if (!fileSize) {
                this.$message.error("文件大小不能超过500MB！！！");
                return false;
            }
            let flag = false;
            flag = uploadAccept(file, "html");
            return flag;
        },
        fileSuccessAttach(res) {
            this.form.noticeAttachedUrl = res.data.filePath;
            this.form.noticeFileName = res.data.fileName;
        },
        change2(file, fileList) {
            const fileSize = file.size / 1024 / 1024 < 500;
            if (!fileSize) {
                this.$message.error("文件大小不能超过500MB！！！");
                return false;
            }
            let flag = false;
            flag = uploadAccept(file, "文档");
            return flag;
        },
        fileSuccessContent(res) {
            this.form.noticeContentUrl = res.data.filePath;
        },
        videoProgress(r, f, l) {
            this.videoLoading = this.$loading({
                lock: true,
                text: "上传中！",
                background: "rgba(0, 0, 0, 0.7)",
            });
        },
        async getContentUpd() {
            await axiosReqCross(
                this.form.noticeContentUrl,
                null,
                null,
                "get"
            ).then((res) => {
                this.noticeContent = res;
            });
        },
        getContent(v) {
			// console.log(v.content)
            this.form.noticeContentHtml = v.content;
            this.form.noticeContent = v.noHtml;
        },
        getCompany() {
            this.$axiosReq(
                company,
                null,
                {
                    pageSize: 10000,
                },
                "get"
            ).then((res) => {
                this.company = res.data.content;
            });
        },
        companyChange(v) {
            if (v.length === 1) {
                this.getDepart(v[0]);
            } else if (v.length > 1) {
                this.depart = [];
                this.form.departIds = [];
            } else {
                this.getDepart(this.$companyId);
            }
        },
        getDepart(id) {
            this.$axiosReq(
                depart,
                null,
                {
                    companyId: id,
                    type: 1,
                    pageSize: 10000,
                },
                "get"
            ).then((res) => {
                this.depart = res.data;
            });
        },
        save() {
            if (this.$textNull(this.noticeContent)) {
                this.$message.error("请填写公告内容！");
                return;
            }
            const html = `<html><meta charset="utf-8"><meta name="viewport" content="maximum-scale=1.0,minimum-scale=1.0,user-scalable=0,width=device-width,initial-scale=1.0" /><body style="font-size:14pt">${this.noticeContent}</body></html>`;
            downFile(html, "公告内容.html");
        },
        // 提交添加
        commit() {
            if (this.$textNull(this.form.noticeTitle)) {
                this.$message.error("请填公告标题！");
                return;
            } else if (this.$textNull(this.form.noticeContentHtml)) {
                this.$message.error("请上传公告内容文件！");
                return;
            } else if (this.$textNull(this.form.failureTime)) {
                this.$message.error("请选择公告有效期！");
                return;
            } else if (this.$admin) {
                if (this.form.companyIds.length === 0) {
                    this.$message.error("请选择公司！");
                    return;
                }
            }
			let src = this.form.noticeContentHtml.substring(0,5)
			if (src != '<meta') {
				this.form.noticeContentHtml =
					'<html><meta charset="utf-8"><meta name="viewport" content="maximum-scale=1.0,minimum-scale=1.0,user-scalable=0,width=device-width,initial-scale=1.0" /><body style="font-size:14pt">' +
					this.form.noticeContentHtml + '</body></html>';
			} else {
				this.form.noticeContentHtml = '<html>' + this.form.noticeContentHtml + '</html>';
			}
            if (!this.$admin) {
                if (this.$companyType == 0) {
                    this.companyIds.push(
                        this.$companyId,
                        ...this.form.companyIds
                    );
                    this.form.companyIds = this.companyIds;
                }
            }
            this.form.noticeContent = this.form.noticeContent.slice(0, 200);
            const loading = this.$loading({
                lock: true,
                text: "正在添加数据请稍后！！！",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            if (this.info.addOr === "add") {
                this.$axiosReq(notice, this.form, null, "post")
                    .then((res) => {
                        this.$message.success(res.msg);
                        this.close();
                        this.$emit("get-list");
                    })
                    .finally((e) => {
                        loading.close();
                    });
            } else {
                this.$axiosReq(notice + this.row.id, this.form, null, "put")
                    .then((res) => {
                        this.$message.success(res.msg);
                        this.close();
                        this.$emit("get-list");
                    })
                    .finally((e) => {
                        loading.close();
                    });
            }
        },
        // 关闭模态框
        close() {
            this.show = false;
            this.$emit("refresh");
        },
    },
};
</script>

<style scoped lang="scss">
.el-form{
    padding-right: 25px;


}

::v-deep .editor{
  .text{
    background-color: #fff!important;
  }
}

</style>
