<template>
	<div class="dashboard-container">
		<Operate v-if="dialog.refresh" :dialog.sync="dialog.show" :row="dialog.row" :info="dialog.info" @get-list="getList"
		 @refresh="refreshDialog" />
		<SetUser v-if="dialog.refresh2" :dialog.sync="dialog.show2" :row="dialog.row" :info="dialog.info" @get-list="getList"
		 @refresh="refreshDialog" />
		<TextShow v-if="noticeTextShow" :row="noticeText" @refresh="refreshDialog" />
		<el-card v-if="readShow">
			<el-row type="flex" justify="space-between" :gutter="20">
				<el-col>
					<span class="text-primary text-pad-right">发布状态</span>
					<el-select v-model="condition.releaseStatus" clearable size="mini" class="input searchInput" @change="changCondition">
						<el-option :value="1" label="已发布" />
						<el-option :value="0" label="未发布" />
					</el-select>
				</el-col>
				<el-col>
					<span class="text-primary text-pad-right">日期</span>
					<el-date-picker v-model="condition.date" type="daterange" class="input searchInput" range-separator="至"
					 start-placeholder="开始日期" end-placeholder="结束日期" clearable value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00','23:59:59']"
					 size="mini" @change="changeTime" />
				</el-col>
				<el-col>
					<el-input v-model="condition.noticeTitle" class="input searchInput" size="mini" suffix-icon="el-icon-search"
					 placeholder="搜索" @input="changCondition" />
				</el-col>
			</el-row>
		</el-card>
		<el-card v-if="readShow" style="margin-top: 20px;">
			<el-row>
				<vxe-toolbar>
					<template v-slot:buttons>
						<el-row type="flex" justify="between">
							<el-col>
								<el-button type="primary" size="mini" @click="openDialog({title:'添加公告',addOr:'add'})">添加公告</el-button>
								<el-button type="danger" size="mini" @click="remove({del:'batch'})">批量删除</el-button>
								<el-button type="primary" size="mini" @click="exportTableE1">导出excel</el-button>
							</el-col>
							<el-col :span="1">
								<vxe-button @click="getList">刷新</vxe-button>
							</el-col>
						</el-row>
					</template>
				</vxe-toolbar>
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
				 align="center" class="vxe-table-element" height="620" row-id="notice_id" :sort-config="{remote: true}"
				 :filter-config="{remote: true}" :checkbox-config="{reserve: true}" :data="table.list" :loading="loading">
					<vxe-table-column type="checkbox" width="60" fixed="left" />
					<vxe-table-column type="seq" width="60" />
					<vxe-table-column field="noticeTitle" title="公告标题" show-overflow="title" />
					<vxe-table-column field="releaseTime" title="发布时间" show-overflow="title" />
					<vxe-table-column field="failureTime" title="失效时间" show-overflow="title" />
					<vxe-table-column title="公告内容">
						<template v-slot="{ row, rowIndex }">
							<div class="textHide" @click="textClick(row.noticeContentUrl)" v-html="row.noticeContent"></div>
						</template>
					</vxe-table-column>
					<vxe-table-column field="publisher" title="发布人" show-overflow="title" />
					<vxe-table-column field="releaseStatus" title="是否发布">
						<template v-slot="{ row, rowIndex }">
							<el-switch ref="switch" v-model="row.releaseStatus" active-color="#13ce66" inactive-color="#ff4949"
							 :active-value="1" :inactive-value="0" @change="(v)=>{changeStatus(v,row)}" />
						</template>
					</vxe-table-column>
					<vxe-table-column title="阅读比例">
						<template v-slot="{ row, rowIndex }">
							<div>{{ row.readingRatio }}%</div>
						</template>
					</vxe-table-column>
					<vxe-table-column title="阅读统计">
						<template v-slot="{ row, rowIndex }">
							<div class="textHide" @click="reading(row)">点击查看</div>
						</template>
					</vxe-table-column>
					<!-- <vxe-table-column
            title="公告附件"
          >
            <template v-slot="{ row, rowIndex }">
              <span v-if="!row.noticeAttachedUrl">无</span>
              <el-link v-else :href="$fileUrl+row.noticeAttachedUrl" type="primary" target="_blank">下载</el-link>
            </template>
          </vxe-table-column> -->
					<vxe-table-column field="" title="操作">
						<template v-slot="{ row, rowIndex }">
							<el-dropdown size="small" type="primary">
								<el-button size="small" type="primary">
									操作<i class="el-icon-arrow-down el-icon--right" />
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item v-if="row.releaseStatus === 1" icon="el-icon-setting" @click.native="openDialog2({title:'设置人员',addOr:'view'},row)">设置人员</el-dropdown-item>
									<el-dropdown-item v-if="row.releaseStatus === 1" icon="el-icon-edit" @click.native="openDialog({title:'查看公告',addOr:'view'},row)">查看</el-dropdown-item>
									<el-dropdown-item v-if="row.releaseStatus === 0" icon="el-icon-edit" @click.native="openDialog({title:'修改公告',addOr:'upd'},row)">修改</el-dropdown-item>
									<!--                  <el-dropdown-item v-if="row.releaseStatus === 0" icon="el-icon-thumb" @click.native="release(row)">发布</el-dropdown-item>-->
									<el-dropdown-item :disabled="row.releaseStatus === 1" icon="el-icon-delete" @click.native="remove({del:'single'},row)">删除</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</vxe-table-column>
				</vxe-table>
				<div style="height: 20px" />
				<el-pagination background :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]"
				 :page-size="table.pageSize" layout="total, ->, sizes, prev, pager, next, jumper" :total="table.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" />
			</el-row>
		</el-card>
		<div v-if="!readShow">
			<el-button size="small" type="primary" style="width: 100px;" @click="readShow = true">返回</el-button>
			<el-button size="small" type="primary" @click="readingNow">刷新</el-button>
			<el-button size="small" type="primary" @click="exportTableE2">导出excel</el-button>
			<div class="text-center">{{ noticeInfo.noticeTitle }}</div>
			<div class="infoBox">
				<div class="flex-row-box border-b">
					<div class="box1 border-r">发布人</div>
					<div class="box2 border-r">{{ noticeInfo.publisher }}</div>
					<div class="box2 border-r">发布时间</div>
					<div class="box2 border-r">{{ noticeInfo.releaseTime }}</div>
					<div class="box2 border-r">全部人数</div>
					<div class="box2">{{ noticeInfo.allReader }}</div>
				</div>
				<div class="flex-row-box border-b">
					<div class="box1 border-r">已读人数</div>
					<div class="box2 border-r">{{ noticeInfo.isRead }}</div>
					<div class="box2 border-r">未读人数</div>
					<div class="box2 border-r">{{ noticeInfo.unRead }}</div>
					<div class="box2 border-r">阅读比例</div>
					<div class="box2">{{ noticeInfo.readingRatio }}%</div>
				</div>
				<div class="flex-row-box border-b">
					<div class="box13 border-r">公告内容</div>
					<div class="box3">
						<div v-html="noticeInfo.noticeContentUrl"></div>
						<!-- <iframe id="myrame" name="myiframe" frameborder="0" align="left" width="100%" height="100%" :src="$fileUrl + noticeInfo.noticeContentUrl">
							<p>你的浏览器不支持iframe标签</p>
						</iframe> -->
					</div>
				</div>
				<div class="flex-row-box border-b">
					<div class="box1 border-r">序号</div>
					<div class="box2 border-r">姓名</div>
					<div class="box2 border-r">电话</div>
					<div class="box2 border-r">部门</div>
					<div class="box2 border-r">岗位</div>
					<div class="box2">是否阅读</div>
				</div>
				<div v-for="(item,index) in tableData" :key="index">
					<div class="flex-row-box border-b">
						<div class="box1 border-r">{{ index + 1 }}</div>
						<div class="box2 border-r">{{ item.realName }}</div>
						<div class="box2 border-r">{{ item.phone }}</div>
						<div class="box2 border-r">{{ item.departName }}</div>
						<div class="box2 border-r">{{ item.positionName }}</div>
						<div class="box2">{{ item.isReadStatus }}</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import {
		bytesToFile
	} from '@/utils/handleByte'
	import {
		rowStyle,
		headerStyle
	} from '@/utils/tableStyleJs'
	import {
		notice,
		noticeRelease,
		noticeCount,
		exportNotice
	} from '@/api'
	import Operate from './Operate'
	import SetUser from './SetUser'
	import TextShow from './TextShow'
	export default {
		name: 'Index',
		components: {
			Operate,
			SetUser,
			TextShow
		},
		data() {
			return {
				loading: false,
				condition: {
					releaseStatus: '',
					noticeTitle: '',
					date: [],
					startDate: '',
					endDate: ''
				},
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 20,
					sortName: '',
					sortBy: ''
				},
				dialog: {
					row: Object,
					info: {},
					refresh: false, // 每次刷新DOM
					refresh2: false, // 每次刷新DOM
					show: false,
					show2: false
				},
				company: [],
				loginInfo: {},
				noticeInfo: {
					'noticeTitle': '',
					'modifyId': '',
					'publisher': '',
					'releaseTime': '',
					'readingRatio': 0,
					'allReader': 0,
					'isRead': 0,
					'unRead': 0
				},
				noticeText: '',
				noticeTextShow: false,
				tableData: [],
				readShow: true,
				noticeCountId: ''
			}
		},
		created() {
			this.getList()
		},
		methods: {
			rowStyle,
			headerStyle,
			// 打开dialog
			openDialog(info, row) {
				this.dialog.refresh = true
				this.dialog.show = true
				this.dialog.info = info
				this.dialog.row = row
			},
			openDialog2(info, row) {
				this.dialog.refresh2 = true
				this.dialog.show2 = true
				this.dialog.info = info
				this.dialog.row = row
			},
			changCondition() {
				this.table.currentPage = 1
				this.getList()
				this.$refs.table.clearCheckboxRow()
			},
			changeTime(v) {
				if (v === null) {
					this.condition.startDate = ''
					this.condition.endDate = ''
				} else {
					this.condition.startDate = v[0]
					this.condition.endDate = v[1]
				}
				this.getList()
			},
			getList() {
				this.loading = true
				this.$axiosReq(notice, null, {
					releaseStatus: this.condition.releaseStatus,
					startDate: this.condition.startDate,
					endDate: this.condition.endDate,
					noticeTitle: this.condition.noticeTitle,
					currentPage: this.table.currentPage,
					pageSize: this.table.pageSize
				}, 'get').then(res => {
					this.table.list = res.data.content
					this.table.total = Number(res.data.totalElements)
				}).finally(e => {
					this.loading = false
				})
			},
			changeStatus(v, row) {
				this.$axiosReq(noticeRelease + row.id, null, null, 'put').then(res => {
					this.$message.success(res.msg)
					this.getList()
				})
			},
			remove(info, row) {
				const id = []
				if (info.del === 'batch') {
					const arr = this.$refs.table.getCheckboxRecords()
					const arr1 = this.$refs.table.getCheckboxReserveRecords()
					const batchDel = arr.concat(arr1)
					for (const i of batchDel) {
						id.push(i.id)
					}
					if (id.length === 0) {
						this.$message.error('请勾选！！！')
						return
					}
					this.$confirm('确认删除吗！！！', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$axiosReq(notice, id, null, 'delete').then(res => {
							this.$message.success(res.msg)
							this.getList(this.condition.company)
						})
					}).catch(() => {
						this.$message.info('取消了删除！！！')
					})
				} else {
					this.$confirm('确认删除吗！！！', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$axiosReq(notice + row.id, null, null, 'delete').then(res => {
							this.$message.success(res.msg)
							this.getList(this.condition.company)
						})
					}).catch(() => {
						this.$message.info('取消了删除！！！')
					})
				}
			},
			release(row) {
				this.$confirm('确认发布吗！！！', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axiosReq(noticeRelease + row.id, null, null, 'put').then(res => {
						this.$message.success(res.msg)
						this.getList()
					})
				}).catch(() => {
					this.$message.info('取消了发布！！！')
				})
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize
				this.table.currentPage = 1
				this.table.list = [];
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage
				this.table.list = [];
				this.getList()
			},
			refreshDialog() {
				this.dialog.refresh = false
				this.dialog.refresh2 = false
				this.noticeTextShow = false
			},
			reading(val) {
				this.tableData = []
				this.noticeCountId = val.id;
				this.$axiosReq(noticeCount + this.noticeCountId, null, {
					pageSize: 10000
				}, 'get').then(res => {
					this.readShow = false
					this.noticeInfo = res.data
					if (res.data.readers && res.data.readers.content && res.data.readers.content.length > 0) {
						this.tableData = res.data.readers.content
					}
				}).finally(e => {
					this.loading = false
				})
			},
			textClick(val) {
				this.noticeText = val
				this.noticeTextShow = true
			},
			exportTableE1() {
				this.loading = true
				this.$axiosReq(exportNotice, null, {
					noticeTitle: this.condition.noticeTitle,
					startDate: this.condition.startDate,
					endDate: this.condition.endDate,
					releaseStatus: this.condition.releaseStatus
				}, 'get').then(res => {
					bytesToFile(res.data.bytes, res.data.fileName)
				}).finally(e => {
					this.loading = false
				})
			},
			exportTableE2() {
				this.loading = true
				this.$axiosReq(exportNotice + '/' + this.noticeCountId, null, null, 'get').then(res => {
					bytesToFile(res.data.bytes, res.data.fileName)
				}).finally(e => {
					this.loading = false
				})
			},
			readingNow() {
				this.tableData = []
				this.$axiosReq(noticeCount + this.noticeCountId, null, {
					pageSize: 10000
				}, 'get').then(res => {
					this.noticeInfo = res.data
					if (res.data.readers && res.data.readers.content && res.data.readers.content.length > 0) {
						this.tableData = res.data.readers.content
					}
					this.$message.success('已刷新')
				}).finally(e => {
					this.loading = false
				})
			},
		}
	}
</script>

<style scoped>
	.textHide {
		/* width: 130px; */
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		color: #1890FF;
		cursor: pointer;
	}

	.text-center {
		/* color: #1890FF; */
		font-size: 20px;
		font-weight: bold;
		padding: 20px;
	}

	.infoBox {
		border: 1px solid #CCCCCC;
		font-size: 16px;
		height: calc(100vh - 180px);
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
	}

	.infoBox::-webkit-scrollbar {
		display: none;
	}

	.border-b {
		border-bottom: 1px solid #CCCCCC;
	}

	.border-r {
		border-right: 1px solid #CCCCCC;
	}

	.col-1890FF {
		color: #1890FF;
	}

	.box1 {
		width: 10%;
		height: 40px;
		line-height: 40px;
		text-align: center;
	}

	.box2 {
		width: 18%;
		height: 40px;
		line-height: 40px;
		text-align: center;
	}

	.box3 {
		width: 90%;
		height: 230px;
		text-align: center;
		overflow-y: scroll;
	}

	.box13 {
		width: 10%;
		height: 230px;
		line-height: 230px;
		text-align: center;
	}
</style>
